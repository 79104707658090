<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      whatsapp: null,
    };
  },
  methods: {
    getWhatsapp() {
      api
        .get("integrations/whatsapp")
        .then((response) => {
          if (response.data.status == "success") {
            this.whatsapp = response.data.whatsapp;
          }
        })
    },
  },
  mounted() {
    this.getWhatsapp();
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Integrações</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <fieldset>
          <legend>Comunicação</legend>

          <div v-if="!whatsapp" class="min-vh-25 d-flex justify-content-center align-items-center">
            <b-spinner variant="default" role="status"></b-spinner>
          </div>

          <template v-else>
            <div>
              <div class="mb-4 d-flex justify-content-between">
                <img class="integration-logo" src="@/assets/images/integrations/whatsapp.svg" />
                <div class="text-center">
                  <span v-if="whatsapp.status == 'inactive'" class="bg-danger rounded py-2 px-3 font-size-12 text-white">INATIVO</span>
                  <span v-else-if="whatsapp.status == 'active'" class="bg-success rounded py-2 px-3 font-size-12 text-white">ATIVO</span>
                  <span v-else-if="whatsapp.status == 'soon'" class="bg-dark rounded py-2 px-3 font-size-12 text-white">EM BREVE</span>
                </div>
              </div>
              <h5>Whatsapp</h5>
              <p class="m-0">
                Integração (não oficial) para envio de mensagens automáticas e notificações de pedidos.
              </p>
            </div>
          </template>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.integration-logo {
  height: 35px;
}

.integration-logo.inactive {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  opacity: 0.4;
}
</style>
